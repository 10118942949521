import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"


const TermsPage = () => {

    return (
        <>
            <Layout black={false}>
                <SEO
                    title={"Differnt Systems | Terms & Conditions"}
                />
                <>
                    <section className="py-15 py-xl-20">
                        <div className="container mt-10">
                            <h1>Terms &amp; Conditions</h1>
                        </div>
                    </section>
                    <section className="py-10 border-top">
                        <div className="container">
                            <div className="row g-4 justify-content-between">
                                <div className="col-md-12">
                                    <h2 className="fs-5">General Terms &amp; Conditions</h2>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-5">
                                        <i>Updated: 07/01/2024</i>
                                    </p>
                                    <a
                                        href="/pdf/General_Terms_07-01-2024.pdf"
                                        target="_blank"
                                    >
                                        <p>
                                            <u>Click Here To View PDF</u>
                                            <i className="bi bi-arrow-up-right" />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-10 border-top">
                        <div className="container">
                            <div className="row g-4 justify-content-between">
                                <div className="col-md-12">
                                    <h2 className="fs-5">Zero-Maintenance WordPress Hosting</h2>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-5">
                                        <i>Updated: 01/30/2022</i>
                                    </p>
                                    <a href="/pdf/WordPress-Hosting_01-30-22.pdf" target="_blank">
                                        <p>
                                            <u>Click Here To View PDF</u>
                                            <i className="bi bi-arrow-up-right" />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-10 border-top">
                        <div className="container">
                            <div className="row g-4 justify-content-between">
                                <div className="col-md-12">
                                    <h2 className="fs-5">Zero-Maintenance Jamstack Hosting</h2>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-5">
                                        <i>Updated: 01/30/2022</i>
                                    </p>
                                    <a href="/pdf/Jamstack-Hosting_01-30-22.pdf" target="_blank">
                                        <p>
                                            <u>Click Here To View PDF</u>
                                            <i className="bi bi-arrow-up-right" />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-10 border-top">
                        <div className="container">
                            <div className="row g-4 justify-content-between">
                                <div className="col-md-12">
                                    <h2 className="fs-5">Zero-Maintenance Custom Cloud Hosting</h2>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-5">
                                        <i>Updated: 01/30/2022</i>
                                    </p>
                                    <a href="/pdf/Custom-Hosting_01-30-22.pdf" target="_blank">
                                        <p>
                                            <u>Click Here To View PDF</u>
                                            <i className="bi bi-arrow-up-right" />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            </Layout>
        </>
    )
}

export default TermsPage
